<template>
  <div id="purchase-process">
    <h1 class="terms__title">購物流程</h1>
    <div class="terms__title-divider" />

    <div class="step1-container">
      <img
        :src="require('@/assets/statics/purchase-1.webp')"
        height="500px"
        alt=""
      />
    </div>
    <img
      :src="require('@/assets/statics/purchase-5.png')"
      height="380px"
      alt=""
    />
    <img
      :src="require('@/assets/statics/purchase-6.png')"
      height="110px"
      alt=""
    />
    <img
      :src="require('@/assets/statics/purchase-2.webp')"
      height="180px"
      alt=""
    />
    <img
      :src="require('@/assets/statics/purchase-3.webp')"
      height="480px"
      alt=""
    />
    <img
      :src="require('@/assets/statics/purchase-4.png')"
      height="500px"
      alt=""
    />
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="stylus">
#purchase-process
  flex(center, flex-start, column)
  padding 50px 0 100px

.terms__title
  font-size $font-size-title
  color #4A9A8A
  padding 10px 0

.terms__title-divider
  height 5px
  background #957200
  border-radius 4px
  width 100%

.step1-container
  width 100%
  flex()

img
  margin-top 80px
  width auto
</style>
